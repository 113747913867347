.modal-shadow {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    overflow-y: hidden;
}

.fade-enter {
    opacity: 0.01;
}

.fade-enter-active {
    opacity: 1;
    transition: all 1s;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0.01;
    transition: all 1s;
}

.background-highlight {
    background: #f1eeee;
}

.react-calendar {
    border-radius: 8px;
    border-color: #dee2e6 !important;
    width: 531px !important;

    .react-calendar__tile--active {
        background: #f2ba00;
        color: #000;
        //border-radius: 50% !important;
        &:enabled:focus {
            background: #f2ba00;
        }
    }

}

#navbar {
    @media screen and (max-width: 992px) {
        display: none;
    }
}

.navbar-burger {
    position: absolute;
    //bottom: 20px;
    //right: 25px;
    font-size: 1.75rem;
    display: none;
    color: #f2ba00;
    border: 1px solid #dee2e6;
    padding: 5px;
    height: 35px;
    width: 35px;
    text-align: center;
    vertical-align: center;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    z-index: 0;
    @media screen and (max-width: 991px) {
        display: block;
        left: 15px;
    }
    @media screen and (min-width: 768px) {
        display: block;
        left: 55px;
        padding: 10px;
        height: 45px;
        width: 45px;
    }
    @media screen and (min-width: 992px) {
        display: none;
    }

    i {
        vertical-align: top;
        padding-top: 1px;
        @media screen and (min-width: 768px) {
            vertical-align: top;
            padding-top: 0;
        }
    }
}

.navbar-expand-lg {
    @media screen and (max-width: 991px) {
        justify-content: center;

    }
}

.border-menu {
    position: fixed;
    top: 0;
    min-height: 100%;
    width: 250px;
    left: -250px;
    background: #fff;
    z-index: 999999999;
    border: 1px solid #dee2e6;
    transition: all 0.4s;
    border-top-right-radius: 8px;

    h2 {
        text-align: right;
        padding: 20px 20px 0 0;
    }

    ul {
        color: #495057;
        margin: 30px 0 0 0;
        padding: 0;

        li {
            font-size: 15px;
            padding: 1rem 1.55rem;
            border: solid #dee2e6;
            border-width: 0 0 1px 0;

            &:first-child {
                border-top: 1px solid #dee2e6;
            }

            i {
                width: 30px;
            }

        }
    }
}

.hide-on-mobile {
    @media screen and (max-width: 991px) {
        display: none;
    }
}

.modal {
    display: block;

    &-content {
        box-shadow: 0 .5rem 2rem 0 rgba(0, 0, 0, .25);
    }
}

.modal-outer {
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    left: 0;
    top: 0;
    z-index: 9999999999;
    width: 100%;
    height: 100%;
}
.account-type-outer {
    display: flex;
    justify-content: flex-start;

    .account-type {

        label {
            cursor: pointer;

        }

        &:not(:last-child) {
            margin-right: 25px;
        }

        input[type=radio] {
            visibility: hidden;
            position: absolute;
            cursor: pointer;

        }

        input[type=radio] + label:before {
            cursor: pointer;

            height: 16px;
            width: 16px;
            margin-right: 6px;
            content: "";
            display: inline-block;
            vertical-align: baseline;
            //border:1px solid #777;
            border-radius: 50%;
            border: 1px solid #dee2e6;
        }

        input[type=radio]:checked + label:before {
            background: #f2ba00;

        }
    }
}
