@import '~open-color/open-color';
@import 'variables';
@import 'bootstrap';

@mixin button-svg-margin($font-size, $line-height) {
    margin-top: ($font-size * $line-height - $font-size) / 2;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

html {
    font-size: 12px;

    @include media-breakpoint-up(md) {
        font-size: 16px;
    }
}

p + p,
p + ul,
p + ol,
ul + p,
ul + ul,
ul + ol,
ol + p,
ol + ul,
ol + ol {
    margin-top: 2rem;
}

strong {
    font-weight: $font-weight-bold;
}

.text {
    &-lg {
        font-size: $font-size-lg;
    }
}

button {
    background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none;
}

.btn {
    > svg {
        display: inline-block;
        width: $btn-font-size;
        height: $btn-font-size;
        vertical-align: top;
        @include button-svg-margin($btn-font-size, $btn-line-height);
    }

    &-lg {
        > svg {
            width: $btn-font-size-lg;
            height: $btn-font-size-lg;
            @include button-svg-margin($btn-font-size-lg, $btn-line-height-lg);
        }
    }

    &-sm {
        > svg {
            width: $btn-font-size-sm;
            height: $btn-font-size-sm;
            @include button-svg-margin($btn-font-size-sm, $btn-line-height-sm);
        }
    }

    .list-group-item & {
        font-size: $font-size-xs;
        padding: .125rem .5rem;
        white-space: nowrap;
    }
}

h1 {
    > svg {
        width: 2rem;
        height: 2rem;
        margin-top: .375rem;
        margin-right: 1rem;
        vertical-align: top;
    }
}

.navbar {
    align-items: start;

    .cta {
        border: 1px solid currentColor;
        border-radius: $border-radius;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .nav-item {
        position: relative;

        .nav-link {
            font-size: $font-size-sm;
            font-weight: $font-weight-bold;
        }

        .bg-primary {
            background: $primary !important;
        }

        .badge {
            position: absolute;
            top: 0;
            right: .25rem;
        }

        svg {
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    &-brand {
        img {
            display: block;
            height: 2.5rem;
            width: auto;
        }
    }

    &-light {
        border-bottom: 1px solid $gray-300;
    }
}

.notifications {
    &__date {
        font-size: .625rem;
        color: $text-muted;
        margin-bottom: .25rem;
    }

    &__description {
        margin: 0;
        line-height: 1.3;
    }

    .navbar & {
        min-width: 20rem;
        padding: 0;
        margin-top: 1rem;
        overflow: hidden;
        font-size: $font-size-xs;
    }

    .navbar &__list {
        max-height: 50vh;
        overflow-y: scroll;

        .list-group-item:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }
    }

    .navbar &__empty {
        padding-top: 4rem;
        padding-bottom: 4rem;
        text-align: center;

        svg {
            display: block;
            width: 4rem;
            height: 4rem;
            margin: 0 auto 1rem;
            color: $gray-200;
        }
    }

    .navbar &__all {
        padding-top: .5rem;
        padding-bottom: .5rem;
        text-align: center;
        font-weight: $font-weight-bold;
        border-top: solid 1px $dropdown-border-color;
    }
}
.badge {
    &-secondary {
        text-transform: uppercase;
        font-size: 62.5%;
        vertical-align: top;
        margin-top: .125rem;
        color: $body-color;
        &-vet {
            background-color: $oc-blue-2;
        }
        &-nurse {
            background-color: $oc-teal-2;
        }
    }
}

.card {
    &-header {
        border: none;
        padding: $card-spacer-x;
    }

    &-title {
        color: inherit;
    }

    &-subtitle {
        color: inherit;
        font-weight: $font-weight-base;
        text-transform: capitalize;
    }

    &-text {
        font-size: $font-size-xs;
        height: 2rem;
        overflow: hidden;

        &-vet {
            height: auto;
        }
    }
}

.vet {
    .profile__rcvs {
        position: absolute;
        top: $card-spacer-x;
        right: $card-spacer-x;
    }

    .profile__avatar {
        width: 2rem;
        margin-bottom: $card-spacer-x;
    }

    &__status {
        &__icon {
            font-weight: $font-weight-bold;

            svg {
                color: $primary;
                display: inline-block;
                width: 1rem;
                height: 1rem;
                margin-right: .5rem;
                vertical-align: top;
            }

            &--disabled {
                color: $text-muted;
                font-weight: $font-weight-base;

                svg {
                    color: $gray-300;
                }
            }
        }

        &__icon + &__icon {
            margin-top: .5rem;
        }
    }
}

.job {
    .card-img-top {
        border-bottom: $card-border-width solid $card-border-color;
    }

    &__title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__date {
        font-weight: $font-weight-base;
        white-space: nowrap;

        svg {
            display: inline-block;
            width: 1.25rem;
            height: 1.25rem;
            margin-left: .5rem;
            vertical-align: top;
            color: $text-muted;
        }
    }

    &__featured {
        position: absolute;
        top: -.625rem;
        right: -.625rem;
        text-transform: uppercase;
        z-index: 20;
        padding: .25rem .75rem;

        svg {
            display: inline-block;
            width: .75rem;
            height: .75rem;
            vertical-align: top;
            margin-left: .25rem;
        }
    }

    &__meta {
        .list-group-item:not(.list-group-item-action) {
            color: $gray-600;
            background-color: $oc-gray-0;
        }
    }
}

.form-label {
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
    color: $gray-600;
}

.hero {
    &--home {
        background: url('../img/james-sutton-705775.jpg') no-repeat bottom right;
        background-size: cover;
    }

    &--full {
        .container {
            height: 100vh;

            > .row {
                height: 100%;
            }
        }
    }

    &__form {
        background: rgba($white, .95);
        box-shadow: .5rem .5rem 2rem 0 rgba($gray-900, .1);
        padding: 2rem 3rem;
        border-radius: 1.5rem;

        &__radius {
            font-weight: $font-weight-bold;
            font-size: $font-size-md;
            color: $primary;
            line-height: 1.2;
        }
    }
}

.lead {
    line-height: $lead-line-height;
}

.navbar {
    &__name {
        display: inline-block;
        @extend .text-truncate;
        max-width: 8rem;
    }

    &__avatar {
        display: inline-block;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        margin: -.5rem 0 -.5rem .5rem;
    }
}

.list-group {
    svg {
        display: inline-block;
        width: 1.6rem;
        height: 1.6rem;
        vertical-align: top;
    }

    .active {
        font-weight: $font-weight-bold;
        color: inherit;
    }
}

.profile {
    &__avatar {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 50%;
    }

    &__contact {
        font-size: $font-size-lg;
        margin: 0;

        svg {
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            vertical-align: top;
            margin: .25rem .5rem 0 0;
            color: $primary;
        }
    }

    &__list {
        .list-group-item {
            color: inherit;
        }

        &__controls {
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        &__search {
            flex: 1;

            .form-control {
                padding-left: $list-group-item-padding-x;
                padding-right: $list-group-item-padding-x;
            }
        }

        &__counter {
            margin-left: 2rem;
            font-size: $font-size-xs;
            color: $text-muted;
        }

        &__pagination {
            margin-left: .5rem;

            .btn {
                padding-left: .5rem;
                padding-right: .5rem;
            }
        }

        &__meta {
            font-size: $font-size-xs;
            font-weight: $font-weight-bold;
            color: $text-muted;

            svg {
                width: .75rem;
                height: .75rem;
                margin-top: .125rem;
                margin-right: .25rem;
            }

            span + span {
                margin-left: .5rem;
            }
        }

        &__featured {
            vertical-align: top;
            margin: .25rem 0 0 .25rem;

            svg {
                display: inline-block;
                width: .75rem;
                height: .75rem;
            }
        }

        &__map {
            margin: .75rem (-1 * $list-group-item-padding-x) (-1 * $list-group-item-padding-y);
        }
    }

    &__rcvs {
        line-height: 1;
        font-size: $font-size-sm;
        font-weight: $font-weight-bold;
    }
}


.wide-background {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        width: 100vw;
        top: 0;
        bottom: 0;
        left: 50%;
        margin-left: -50vw;
        z-index: -1;
        background: $gray-100;
    }
}

.placeholder {
    animation: pulse 1s infinite ease-in-out;

    &__text {
        height: 1rem;

        &--lg {
            height: 1.5rem;
        }

        &--sm {
            height: .75rem;
        }
    }

    &__text + &__text {
        margin-top: .25rem;
    }

    &__button {
        height: 2rem;
        border-radius: $border-radius;
    }
}

@keyframes pulse {
    0% {
        background-color: $gray-200;
    }
    50% {
        background-color: $gray-300;
    }
    100% {
        background-color: $gray-200;
    }
}

.loader {
    display: block;
    position: relative;

    div {
        position: absolute;
        top: 0;
        height: 2rem;
        width: .5rem;
        background: $primary;
        animation: loader 1s ease-out infinite;

        &:nth-child(1) {
            left: 0;
            animation-delay: -.4s;
        }

        &:nth-child(2) {
            left: 1rem;
            animation-delay: -.2s;
        }

        &:nth-child(3) {
            left: 2rem;
            animation-delay: 0;
        }
    }
}

.custom-control-label {
    svg {
        display: inline-block;
        vertical-align: top;
        width: 1.5rem;
        height: 1rem;
        margin: .25rem .5rem 0 .25rem;
    }
}

.react-datepicker-wrapper {
    display: block;
    width: 100%;
    .react-datepicker__input-container {
        width: 100%;
    }
}

.form-control:focus.has-error {
    background: #ff9090;

}.form-control.has-error {
    background: #ff9090;

}

@keyframes loader {
    0% {
        transform: scaleY(.25);
    }

    10% {
        transform: scaleY(1);
    }

    100% {
        transform: scaleY(.25);
    }
}

.footer {
    background: $gray-100;
    font-size: $font-size-xs;
}

.cookies {
    background: rgb(230, 230, 230);
    font-size: .875rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: .5rem 0;
    z-index: 50;
}

.custom-checkbox .custom-control-label:before{
    border-radius: .25rem;
}

.rate-field {
    position: relative;
    display: inline-block;
}

.rate-field::after {
    position: absolute;
    right: 5px;
    top: 1px;
    content: attr(data-rate-field);
    pointer-events: none;
    opacity: 0.6;
}

// for Messages.js
.convBack {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.1;
    color: #f2ba00;
    > svg {
        width: 2rem;
        height: 2rem;
        margin-top: .375rem;
        margin-right: 1rem;
        vertical-align: top;
    }
}
.section {
    &__header {
        font-size: 2.5rem;

        @include media-breakpoint-up(md) {
            font-size: 3rem;
        }
    }

    &__screen {
        display: block;
        width: 100%;
        height: auto;
        box-shadow: .5rem .5rem 2rem 0 rgba($gray-900, .2);
        border-radius: .5rem;
    }

    &__icon {
        display: block;
        height: 3rem;
        width: auto;
        color: $primary;
    }

    &__subheader {
        font-size: 2.5rem;
        font-weight: $font-weight-light;
        color: inherit;

        @include media-breakpoint-up(md) {
            font-size: 3rem;
        }
    }

    &--gradient {
        background: linear-gradient(to bottom, $gray-100 0%, $white 75%);
    }

    &--light {
        background: $gray-100;
    }

    &--primary {
        color: $white;
        background: $primary;
    }

    &--primary &__header {
        color: inherit;
    }
}

.breadcrumb {
    font-size: $font-size-sm;
    color: $gray-600;

    .active {
        font-weight: $font-weight-bold;
    }

    a {
        color: inherit;
    }
}
