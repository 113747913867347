@import '~open-color/open-color';
@import 'variables';

.messages {
    overflow: hidden;

    &__inner {
        min-height: 30rem;
        height: 50vh;
        display: flex;
        padding: 0;
    }

    &__users {
        width: percentage(1 / 3);
        border-right: 1px solid $card-border-color;
    }

    &--mobile &__users {
        width: 100%;
        border-right: 0;
    }

    &__user {
        padding: .5rem;
        font-size: .75rem;
        display: flex;
        align-items: center;

        &__avatar {
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            margin-right: .5rem;
        }

        &__data {
            max-width: 100%;
            line-height: 1.2;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &--mobile &__user {
        padding: 1rem;
        font-size: 1rem;
    }

    &--mobile &__user__avatar {
        width: 4rem;
        height: 4rem;
        margin-right: 1rem;
    }

    &__content {
        width: percentage(2 / 3);
        overflow-y: scroll;

        &__inner {
            padding: 1rem;
        }
    }

    &--mobile &__content {
        width: 100%;
    }

    &__footer {
        padding: .5rem;
        background: none;

        .form-check {
            line-height: 1;

            &-input {
                margin-top: 0;
            }

            label {
                margin: 0;
            }
        }

        .form-control {
            padding: 0 .25rem;
            border: 0;
            margin-bottom: .5rem;
        }
    }

    &__separator {
        color: $primary;
        display: flex;
        align-items: center;
        text-align: center;
        font-size: .75rem;
        font-weight: $font-weight-bold;
        margin-bottom: 1rem;

        &:before,
        &:after {
            content: '';
            flex: 1;
            border-bottom: 1px solid $card-border-color;
        }

        &:before {
            margin-right: .5rem;
        }

        &:after {
            margin-left: .5rem;
        }
    }

    &__single {
        display: flex;
        margin-bottom: 1rem;

        &__content {
            flex-grow: 1;
        }

        &--receiver &__content {
            order: 1;
            text-align: right;
        }

        &__avatar {
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            margin-right: .5rem;
        }

        &--receiver &__avatar {
            order: 12;
            margin-left: .5rem;
            margin-right: 0;
        }

        &__header {
            line-height: 1rem;
            display: flex;
            font-size: .75rem;
        }

        &--receiver &__header {
            justify-content: flex-end;
        }

        &__user {
            order: 1;
        }

        &__date {
            order: 2;
            margin-left: .5rem;
        }

        &--receiver &__date {
            order: 0;
            margin-left: 0;
            margin-right: .5rem;
        }
    }

    &--mobile &__single__avatar {
        width: 2rem;
        height: 2rem;
    }

    &--mobile &__single__header {
        font-size: 1rem;
        line-height: 2rem;
    }
}
