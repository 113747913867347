$darkteal: #102d37;
$lightteal: #34737d;
$lightred: #ffb5b5;
$darkred: #ff4a4a;
$lightgreen: #0c6d0d;

// bootstrap

$font-family-sans-serif: 'Roboto', serif;

$font-size-base: 1rem;
$font-size-xl: ($font-size-base * 1.5);
$font-size-lg: ($font-size-base * 1.25);
$font-size-md: ($font-size-base * 1.125);
$font-size-sm: ($font-size-base * .875);
$font-size-xs: ($font-size-base * .75);

$line-height-base: 1.6;
$line-height-lg: $line-height-base;
$line-height-sm: $line-height-base;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

$white: #fff;
$gray-100: $oc-gray-1;
$gray-200: $oc-gray-2;
$gray-300: $oc-gray-3;
$gray-400: $oc-gray-4;
$gray-500: $oc-gray-5;
$gray-600: $oc-gray-6;
$gray-700: $oc-gray-7;
$gray-800: $oc-gray-8;
$gray-900: $oc-gray-9;
$black: #000;

$blue: $oc-blue-7;
$indigo: $oc-indigo-7;
$purple: $oc-grape-7;
$pink: $oc-pink-7;
$red: $oc-red-7;
$orange: $oc-orange-7;
$yellow: $oc-yellow-7;
$green: $oc-green-7;
$teal: $oc-teal-7;
$cyan: $oc-cyan-7;

$primary: #f2ba00;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

$spacer: 1rem;
$spacers: (
        0: 0,
        1: ($spacer * .25),
        2: ($spacer * .5),
        3: $spacer,
        4: ($spacer * 2),
        5: ($spacer * 4),
);

$grid-columns: 12;
$grid-gutter-width: 4rem;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
);

$container-max-widths: (
        sm: 640px,
        md: 720px,
        lg: 960px,
        xl: 1200px,
);

$embed-responsive-aspect-ratios: (
                (21 9),
                (16 9),
                (4 3),
                (3 1),
                (3 2),
                (1 1),
);

//

$body-bg: $white;
$body-color: $gray-900;

$border-radius: .5rem;
$border-radius-lg: .5rem;
$border-radius-sm: .5rem;

$link-color: $gray-900;

// typo

$paragraph-margin-bottom: 0;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.1;
$headings-color: $primary;
$headings-margin-bottom: 0;
$lead-font-size: 1.5rem;
$lead-font-weight: $font-weight-light;
$lead-line-height: 1.4;

// navs

$nav-tabs-border-color: $gray-300;

// navbar

$navbar-padding-y: 2rem;
$navbar-padding-x: 2rem;
$navbar-nav-link-padding-x: .5rem;

$navbar-brand-padding-y: 0;

$navbar-dark-color: $white;
$navbar-dark-hover-color: rgba($white, .75);
$navbar-dark-active-color: $white;

// dropdowns

$dropdown-border-color: $gray-300;

// forms

$input-btn-padding-x: 1rem;
$input-btn-padding-x-sm: .75rem;
$input-btn-padding-x-lg: 1.5rem;
$input-border-color: $gray-300;
$input-placeholder-color: $gray-400;
$btn-font-weight: $font-weight-bold;

// image

$thumbnail-padding: .5rem;

// card

$card-cap-bg: $gray-100;
$card-border-color: $gray-300;
$card-spacer-x: 1rem;
$card-spacer-y: .5rem;

// list group

$list-group-border-color: $gray-300;

// badges

$badge-padding-x: .6rem;

// breadcrumbs

$breadcrumb-bg: none;
$breadcrumb-padding-x: 0;
$breadcrumb-padding-y: .5rem;
