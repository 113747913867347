/* This fires as soon as the element enters the DOM */
.sigin-transition-enter{
    opacity: 0;
}
/* This is where we can add the transition*/
.sigin-transition-enter-active{
    opacity: 1;
    transition: opacity 200ms;
}
/* This fires as soon as the this.state.showList is false */
.sigin-transition-exit{
    opacity: 1;

}
/* fires as element leaves the DOM*/
.sigin-transition-exit-active{
    transition: opacity 200ms;
}